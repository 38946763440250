import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 56rem;
  color: #fff;
  height: 70vh;
  justify-content: center;
  padding: 36px;
`;

export const MainHeading = styled.h1`
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0px;
  color: inherit;
  z-index: 1;
  margin-bottom: 12px;
`;
export const SubHeading = styled.h2`
  font-size: 4em;
  margin: 0px;
  color: inherit;
  z-index: 1;

  @media screen and (max-width: 450px) {
    font-size: 2.8em;
    width: 95%;
  }
`;

export const InputContainer = styled.form`
  display: flex;
  flex-direction: row;
  position: relative;
`;

// Create a styled component for the error message
export const ErrorMessage = styled.p`
  position: absolute; /* Position it absolutely */
  top: 100%; /* Position it below the input */
  left: 0; /* Align to the left */
  margin-top: 12px; /* Add some space between the input and error message */
  font-size: 12px;
`;

export const Input = styled.input`
  background: none;
  width: fit-content;
  border: 1px solid white;
  border-radius: 4px;
  padding: 8px;
  margin-right: 4px;
  font-size: 16px;
  color: white;
`;

export const Button = styled.button`
  width: fit-content;
  border-radius: 4px;
  background-color: #fff;
  color: #000;
  border: none;
  font-size: 16px;
  cursor: pointer;
`;
