import React, { useRef } from "react";
import { useForm, ValidationError } from "@formspree/react";
import {
  FormContainer,
  FormHeading,
  Fieldset,
  Legend,
  Input,
  SubmitButton,
  TextArea,
} from "./form-styles";
import { Toaster, ErrorToast } from "../toaster/toaster";
import { toast } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { trackEvent } from "../../helpers";

export const ContactForm = () => {
  const [state, handleSubmit] = useForm("xzblnjbw");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [reason, setReason] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [capVal, setCapVal] = useState(null);

  const isMounted = useRef(false);

  const notify = () => {
    if (!isMounted.current) {
      toast.info("Successfully submitted!", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      isMounted.current = true;
    }
  };

  const errorNotify = () => {
    if (!isMounted.current) {
      toast.info("Error submitting form", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      isMounted.current = true;
    }
  };

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setReason("");
    setMessage("");
    setEmail("");
    setCapVal(null)
  };

  useEffect(() => {
    if (state.succeeded) {
      notify();
      resetForm();
    }
  }, [state.succeeded]);

  
  useEffect(() => {
    if (state.errors) {
      errorNotify();
    }
  }, [state.errors]);

  return (
    <FormContainer onSubmit={handleSubmit}>
      <FormHeading>Send a message</FormHeading>
      <Fieldset>
        <Legend>Get in touch with Renee</Legend>
        <Input
          name="firstname"
          type="text"
          placeholder="First name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
        />
        <ValidationError field="firstname" errors={state.errors} />
        <Input
          name="lastname"
          type="text"
          placeholder="Last name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <ValidationError field="lastname" errors={state.errors} />
        <Input
          name="email"
          type="email"
          placeholder="Email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <ValidationError field="email" errors={state.errors} />
        <Input
          name="reason"
          type="text"
          placeholder="Reason for contact"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          required
        />
        <ValidationError field="reason" errors={state.errors} />
        <TextArea
          name="message"
          placeholder="Your message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
        <ValidationError field="message" errors={state.errors} />
        {state.succeeded && <Toaster />}
        {state.errors && <ErrorToast />}
        <div>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
            onChange={(val: any) => setCapVal(val)}
          />
        </div>

        <SubmitButton
          isDisabled={!capVal}
          disabled={state.submitting || !capVal}
          onClick={() => trackEvent("Send message clicked")}
        >
          Send message
        </SubmitButton>
      </Fieldset>
    </FormContainer>
  );
};
