import React, { useEffect, useState } from "react";
import "./App.css";
import { MobileNav, NavBar } from "./components/navbar";
import { Routes, Route, useLocation } from "react-router-dom";
import { Error, Home, About, Resume, Podcast, Contact } from "./pages";
import { useViewportDimensions } from "./hooks/viewPortDimensions";

interface AppProps {
  handleLogout: () => void;
}

function App({ handleLogout }: AppProps) {
  const location = useLocation();
  const [isMenuIconIsClicked, setIsMenuIconClicked] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [location.pathname]);

  const isResumePage = location.pathname === "/resume";

  //  check to know which style of navbar to show
  const isValidRoute =
    location.pathname === "/" ||
    location.pathname === "/home" ||
    location.pathname === "/resume" ||
    location.pathname === "/about" ||
    location.pathname === "/podcast" ||
    location.pathname === "/contact";

  // check for mobile dimensions

  const { width } = useViewportDimensions();
  const isMobile = width <= 728;

  const displayFullLengthMobileMenu = isMenuIconIsClicked && isMobile;

  // to make sure the logo and navlink caret is the correct colour when toggling between mobile and desktop
  useEffect(() => {
    if (!isMobile) {
      setIsMenuIconClicked(false);
    }
  }, [isMobile]);
  return (
    <>
      <NavBar
        isValidRoute={isValidRoute}
        isResumePage={isResumePage}
        isMobile={isMobile}
        handleLogout={handleLogout}
        isMenuIconClicked={isMenuIconIsClicked}
        setIsMenuIconClicked={setIsMenuIconClicked}
      />
      {displayFullLengthMobileMenu ? (
        <MobileNav setIsMenuIconClicked={setIsMenuIconClicked} />
      ) : (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/podcast" element={<Podcast />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Error />} />
        </Routes>
      )}
    </>
  );
}

export default App;
