import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import posthog from "posthog-js";
import { Background } from "./components/background";
import {
  Button,
  Container,
  ErrorMessage,
  Input,
  InputContainer,
  MainHeading,
  SubHeading,
} from "./index.styles";
import { Slide } from "react-awesome-reveal";
import { TypingEffect } from "./components/typing-effect/typing-effect";
import { useViewportDimensions } from "./hooks/viewPortDimensions";
import { mobilePhrases, phrases } from "./helpers";

// Initialize PostHog
posthog.init("phc_23Fz0pEhUmH63zXpN04hoL7ROeRpi0ZGCPg9qQfZF0o", {
  api_host: "https://app.posthog.com",
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

function PasswordProtectedApp() {
  const [password, setPassword] = useState<string>("");
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [displayErrorToast, setDisplayErrorToast] = useState<boolean>(false);
  const [attemptCount, setAttemptCount] = useState<number>(0); // Track password attempts
  const [emptyInputWarning, setEmptyInputWarning] = useState<boolean>(false); // Track empty input warning

  const { width } = useViewportDimensions();
  const isMobile = width <= 728;

  const correctPassword = "Typescript123";

  // Error messages in a sensible sequence
  const errorMessages = [
    "Oops! That’s not it. Please try again.", // 1st attempt
    "Oops! That’s not it. Please try again.", // 1st attempt
    "Hmm, still not the right password. You can do it!", // 2nd attempt
    "Nice effort! But that’s not quite right. Don’t give up!", // 3rd attempt
    "So close, yet so far! Maybe a little break will help?", // 4th attempt
    "Hmm, still incorrect. At this point, you might want to take a breather.", // 5th attempt
    "I admire your tenacity! If you keep this up, I might just give you the password 😉", // 6th attempt and beyond
  ];

  // Check for stored authentication in sessionStorage
  useEffect(() => {
    const storedAuth = sessionStorage.getItem("isAuthenticated");
    if (storedAuth === "true") {
      setIsAuthenticated(true);
    }
  }, []);

  const handlePasswordSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Check for empty input
    if (!password.trim()) {
      setEmptyInputWarning(true); // Show warning for empty input
      return; // Prevent further processing
    }

    // Handle password authentication
    if (password === correctPassword) {
      setIsAuthenticated(true);
      setPassword(""); // Clear input field after authentication
      setAttemptCount(0); // Reset attempt count after successful login
      setEmptyInputWarning(false); // Clear empty input warning
      sessionStorage.setItem("isAuthenticated", "true"); // Store authentication status
    } else {
      // Increment attempt count only if the password is incorrect
      setAttemptCount((prev) => prev + 1); // Increment attempt count
      setDisplayErrorToast(true); // Display error message
      setEmptyInputWarning(false); // Clear empty input warning
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    sessionStorage.removeItem("isAuthenticated"); // Clear authentication status
  };

  // Hide error message when user starts typing again
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setDisplayErrorToast(false); // Hide error message
    setEmptyInputWarning(false); // Hide empty input warning
  };

  const desktopCopy =
    "Please enter your password to access Renée-Louise's personal portfolio.";
  const mobileCopy = "Enter password to explore ✨ ";

  if (!isAuthenticated) {
    return (
      <>
        <Background />
        <Container>
          <Slide triggerOnce>
            <SubHeading>
              <TypingEffect phrases={isMobile ? mobilePhrases : phrases} />
            </SubHeading>
          </Slide>
          <MainHeading>{isMobile ? mobileCopy : desktopCopy}</MainHeading>
          <InputContainer onSubmit={handlePasswordSubmit}>
            <Input
              type="password"
              value={password}
              onChange={handleInputChange} // Call this when typing
              placeholder="Enter password"
            />
            <Button type="submit">Submit</Button>
            {emptyInputWarning && (
              <ErrorMessage>
                Uh-oh! Please type something in before you submit.
              </ErrorMessage>
            )}
            {displayErrorToast && (
              <ErrorMessage>
                {
                  attemptCount < 7
                    ? errorMessages[attemptCount]
                    : errorMessages[6] // Show last message after 5 attempts
                }
              </ErrorMessage>
            )}
          </InputContainer>
        </Container>
      </>
    );
  }

  // Once authenticated, render the App
  return (
    <BrowserRouter>
      <App handleLogout={handleLogout} />
    </BrowserRouter>
  );
}

root.render(
  <React.StrictMode>
    <PasswordProtectedApp />
  </React.StrictMode>
);

// For performance monitoring
reportWebVitals();
